import React from "react"
import {externalLinks, pagesLinks} from "../../../pages-data/_V2/common/links"

import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			title: "Чем ещё поможем?",
			items: [
				{
					icon: <div className={styles.servicesItem__iconOfd} />,
					title: <span>Подключение ОФД</span>,
					price: "от 9 000 ₸",
					href: externalLinks.ofd,
				},
				{
					icon: <div className={styles.servicesItem__iconEquipment} />,
					title: <span>Подключение оборудования</span>,
					price: "от 24 000 ₸",
					href: externalLinks.quickrestroPrice,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconNomenclature} />,
					title: <span>Заполнение номенклатуры</span>,
					price: "от 9 000 ₸",
					href: externalLinks.quickrestroPrice,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconCashbox} />,
					title: <span>Постановка кассы на&nbsp;учёт</span>,
					price: "от 9 000 ₸",
					href: externalLinks.fiskalnog,
				},
				{
					icon: <div className={styles.servicesItem__iconEducation} />,
					title: <span>Обучение персонала</span>,
					price: "от 17 000 ₸",
					href: externalLinks.quickrestroPrice,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconAudit} />,
					title: <span>Аудит инвентаризации</span>,
					price: "от 30 000 ₸",
					href: externalLinks.quickrestroPrice,
					hrefTarget: '_blank'
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Тағы қандай көмек көрсетеміз?",
			items: [
				{
					icon: <div className={styles.servicesItem__iconOfd} />,
					title: <span>ФДО-ға қосу</span>,
					price: "от 9 000 ₸",
					href: externalLinks.ofd,
				},
				{
					icon: <div className={styles.servicesItem__iconEquipment} />,
					title: <span>Жабдықты қосу</span>,
					price: "от 24 000 ₸",
					href: externalLinks.quickrestroPrice,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconNomenclature} />,
					title: <span>Номенклатураны толтыру</span>,
					price: "от 9 000 ₸",
					href: externalLinks.quickrestroPrice,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconCashbox} />,
					title: <span>Кассаны есепке қою</span>,
					price: "от 9 000 ₸",
					href: externalLinks.fiskalnog,
				},
				{
					icon: <div className={styles.servicesItem__iconEducation} />,
					title: <span>Персоналды оқыту</span>,
					price: "от 17 000 ₸",
					href: externalLinks.quickrestroPrice,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconAudit} />,
					title: <span>Түгендеу аудиті</span>,
					price: "от 30 000 ₸",
					href: externalLinks.quickrestroPrice,
					hrefTarget: '_blank'
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: "Чем ещё поможем?",
			items: [
				{
					icon: <div className={styles.servicesItem__iconOfd} />,
					title: <span>Подключение ОФД</span>,
					price: "от 50 Р",
					href: pagesLinks.service.href,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconEquipment} />,
					title: <span>Подключение оборудования</span>,
					price: "от 50 Р",
					href: pagesLinks.service.href,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconNomenclature} />,
					title: <span>Заполнение номенклатуры</span>,
					price: "от 90 Р",
					href: pagesLinks.service.href,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconCashbox} />,
					title: <span>Постановка кассы на&nbsp;учёт</span>,
					price: "от 50 Р",
					href: pagesLinks.service.href,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconEducation} />,
					title: <span>Обучение персонала</span>,
					price: "от 400 Р",
					href: pagesLinks.service.href,
					hrefTarget: '_blank'
				},
				{
					icon: <div className={styles.servicesItem__iconAudit} />,
					title: <span>Аудит инвентаризации</span>,
					price: "от 250 Р",
					href: pagesLinks.service.href,
					hrefTarget: '_blank'
				},
			],
		}
	}

	return {
		title: "Чем ещё поможем?",
		items: [
			{
				icon: <div className={styles.servicesItem__iconOfd} />,
				title: <span>Подключение ОФД</span>,
				price: "от 1890 ₽",
				href: externalLinks.podklyuchenieKOfd,
				hrefTarget: '_self'
			},
			{
				icon: <div className={styles.servicesItem__iconEquipment} />,
				title: <span>Подключение оборудования</span>,
				price: "от 4500 ₽",
				href: externalLinks.quickrestroPrice,
				hrefTarget: '_self'
			},
			{
				icon: <div className={styles.servicesItem__iconAtol} />,
				title: <span>Обновление ПО&nbsp;АТОЛ</span>,
				price: "от 2290 ₽",
				href: externalLinks.obnovlenieProshivki,
				hrefTarget: '_self'
			},
			{
				icon: <div className={styles.servicesItem__iconEgais} />,
				title: <span>Настройка ЕГАИС</span>,
				price: "от 1990 ₽",
				href: externalLinks.nastroykaLichnogo,
				hrefTarget: '_self'
			},
			{
				icon: <div className={styles.servicesItem__iconNomenclature} />,
				title: <span>Заполнение номенклатуры</span>,
				price: "от 4000 ₽",
				href: externalLinks.quickrestroPrice,
				hrefTarget: '_self'
			},
			{
				icon: <div className={styles.servicesItem__iconCashbox} />,
				title: <span>Постановка кассы на&nbsp;учёт</span>,
				price: "от 1990 ₽",
				href: externalLinks.postavnovkaFiskalnog,
				hrefTarget: '_self'
			},
			{
				icon: <div className={styles.servicesItem__iconEducation} />,
				title: <span>Обучение персонала</span>,
				price: "от 5990 ₽",
				href: externalLinks.quickrestroPrice,
				hrefTarget: '_self'
			},
			{
				icon: <div className={styles.servicesItem__iconAudit} />,
				title: <span>Аудит инвентаризации</span>,
				price: "от 4990 ₽",
				href: externalLinks.quickrestroPrice,
				hrefTarget: '_self'
			},
		],
	}
}
